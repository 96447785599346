// Body
// $body-bg: #f8fafc;
$body-bg: #fff;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
// $green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$green: #32cd32;
$hover-green: #28b928;

$dark: #262626;

$white: #fff;

$grey: #d3d3d3;

$main-heading: #c69737;
$secondary-heading: #1d1d1b;
$main-text: #fff;
$secondary-text: #d3d3d3;
$light-grey: #d3d3d3;
$background-color: #384151;
