// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// @import url("//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

.page-title {
    color: $green;
}

.section {
    /* min-height: 150px; */
    min-height: 300px;
    height: auto;
    width: 100%;
    // margin-top: 50px;
    // margin-bottom: 50px;
    padding-top: 70px;
    padding-bottom: 70px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $main-heading;
    }

    p {
        font-size: 1.5em;
    }
}
.btn-green {
    background-color: $green;
    border-color: $green;
    color: white;
}
.btn-green:hover {
    background-color: $hover-green;
    border-color: $hover-green;
    color: #fff;
}

// .section.green {
//     background-color: $green;
//     color: white;
// }

.section.a {
    background-color: $background-color;

    color: $main-text;
}

.section.b {
    background-color: #fff;
    color: $secondary-heading;
}

.section.grey {
    background-color: $grey;
}

div.full-height {
    height: 100%;
}

div.container,
div.container-fluid {
    padding-right: 0px;
    padding-left: 0px;
}

div.row {
    margin-right: 0px;
    margin-left: 0px;
}

.nav-logo {
    height: 30px;
}

img.image-header {
    width: 400px;
    margin-bottom: 35px;
}

#footer {
    ul {
        margin-left: 0;
        padding-left: 0 !important;
    }
    li {
        margin-left: 0;
        // padding-left: 0 !important;
    }
}

.abso-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/**
      *
      *
      * Pure Css Loader styles
      *
      *
      */
.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}
.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    // background: $green;
    background: $background-color;
    margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#footer {
    a,
    p {
        color: black;
    }
    background-color: $light-grey;
}

.bio-quote {
    font-size: 1.2em !important;
    // color: #212529;
}

#powered-by {
    // background-color: $green;
    background-color: $background-color;
    color: #fff;
    img {
        width: 55px;
    }
}

div.content-wrapper {
    min-height: 90vh;
}

ul.sto_footer_media {
    li.nav-link {
        padding-left: 0 !important;
    }
}

#company-number {
    font-size: 0.9rem;
}

#company-email {
    font-size: 0.9rem;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(197, 150, 53, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

button.btn.btn-primary {
    background-color: $background-color;
    border-color: $background-color;
}

.btn.btn-tg-blue {
    background-color: $background-color;
    border-color: $background-color;
    color: white;
}

#app {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}

.navbar-icon {
    .navbar-nav {
        .nav-link {
            > .fa {
                position: relative;
                width: 36px;
                font-size: 24px;
                top: 3px;
                line-height: 12px;

                > .badge {
                    font-size: 0.75rem;
                    position: absolute;
                    right: 0;
                    font-family: sans-serif;
                    top: -10px;
                }
            }
        }
    }
    .profile-img {
        width: 50px;
        height: 50px;
    }
}

ul.list-no-padding {
    li {
        padding: 0 !important;
    }
}


li.grey-background {
    opacity: 0.5 !important;
}
